import React, { useState } from "react";

const MenuIcon = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Define styles using object notation for React
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      borderRadius: "8px",
    },
    button: {
      position: "relative",
      width: "40px",
      height: "40px",
      background: "none",
      border: "none",
      cursor: "pointer",
      outline: "none",
    },
    hamburger: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "24px",
      height: "20px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    bar: {
      display: "block",
      height: "2px",
      width: "100%",
      backgroundColor: "#047ED9",
      borderRadius: "2px",
      transition: "all 0.3s ease-in-out",
      transformOrigin: "center",
    },
    textInfo: {
      marginTop: "16px",
      textAlign: "center",
    },
    statusText: {
      fontWeight: "bold",
    },
    helperText: {
      fontSize: "14px",
      color: "#6b7280",
      marginTop: "8px",
    },
  };

  // Specific styles for each bar when menu is open
  const topBarStyle = {
    ...styles.bar,
    transform: isOpen ? "translateY(9px) rotate(45deg)" : "none",
  };

  const middleBarStyle = {
    ...styles.bar,
    opacity: isOpen ? 0 : 1,
    transform: isOpen ? "scale(0.1)" : "none",
  };

  const bottomBarStyle = {
    ...styles.bar,
    transform: isOpen ? "translateY(-9px) rotate(-45deg)" : "none",
  };

  return (
    <div style={styles.container}>
      <button
        style={styles.button}
        onClick={toggleMenu}
        aria-label={isOpen ? "Close menu" : "Open menu"}
      >
        <div style={styles.hamburger}>
          {/* First bar */}
          <span style={topBarStyle}></span>

          {/* Middle bar */}
          <span style={middleBarStyle}></span>

          {/* Last bar */}
          <span style={bottomBarStyle}></span>
        </div>
      </button>
    </div>
  );
};

export default MenuIcon;
