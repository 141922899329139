import dayjs from "dayjs";
import hotelInfoTypes from "../actionType/hotelInfoTypes";

const initialState = {
  hotelData: {},
  fetching: true,
  selectedRooms: [],
  selectedAddons: [],
  selectedRatePlans: [],
  selectedHotelAddons: [],
  rating: 3.9,
  review: [],
  totalPeople: 2,
  totalAdult: 2,
  totalChild: 0,
  totalInfant: 0,
  dateRange: {},
  dateCount: 1,
  pendingReasons: [],
  instaLinks: [],
  isRatePlans: false,
  promotions: [],
  faqs: [],
  policies: [],
  payments: [],
  reviews: [],
  reviews_Arjun: [],

  showHourly: false,
  hourlyDate: dayjs(),
  isHourlyRoom: false,
  roomSlots: {},
  hourlyTime: dayjs()
    .add(1, "hour")
    .set("minute", 0)
    .set("second", 0)
    .set("millisecond", 0)
    .format("hh:mm A"),
  hourlyHour: null,
  livePricing: [],
  taxes: [],
  updatedRoomCount: {},
  couponCode: {},
  couponName: "",
  paymentProvider: {},
  scriptTagId: null,
  Retargeting: false,
  CalendarPrices:{}
};

export const hotelInfoReducer = (
  state = { ...initialState },
  { type, payload }
) => {
  switch (type) {
    case hotelInfoTypes.FETCHING: {
      return { ...state, fetching: payload };
    }

    case hotelInfoTypes.SET_SLOTS: {
      return { ...state, roomSlots: payload };
    }
    case hotelInfoTypes.SET_HOUR: {
      return { ...state, hourlyHour: payload };
    }

    case hotelInfoTypes.FETCH_REVIEW: {
      return { ...state, reviews: payload || [] };
    }

    case hotelInfoTypes.FETCH_REVIEW_Arjun: {
      return { ...state, reviews_Arjun: payload || [] };
    }

    case hotelInfoTypes.SHOW_HOURLY: {
      return { ...state, showHourly: payload };
    }

    case hotelInfoTypes.SET_HOURLY_TIME: {
      return { ...state, hourlyTime: payload };
    }

    case hotelInfoTypes.SET_SHOW_HOURLY: {
      return { ...state, isHourlyRoom: payload };
    }

    case hotelInfoTypes.DETAILS: {
      return { ...state, hotelData: payload };
    }

    case hotelInfoTypes.SET_IS_RATEPLAN: {
      return { ...state, isRatePlans: payload };
    }

    case hotelInfoTypes.SET_PAYMENT_PROVIDER: {
      return { ...state, paymentProvider: payload };
    }

    case hotelInfoTypes.SELECT_ROOM: {
      var arr = [];
      arr.push(payload);

      return { ...state, selectedRooms: [...state.selectedRooms, ...arr] };
    }

    case hotelInfoTypes.SELECT_RP: {
      let arr = [];
      arr.push(payload);
      return {
        ...state,
        selectedRatePlans: [...state.selectedRatePlans, ...arr],
      };
    }

    case hotelInfoTypes.REMOVE_RP: {
      const filteredRps = state?.selectedRatePlans?.filter(
        (rp) => rp?.id !== payload
      );
      return {
        ...state,
        selectedRatePlans: [...filteredRps],
      };
    }

    case hotelInfoTypes.UPDATE_RP: {
      const { obj: newRp, rmRpId } = payload;
      const filteredRps = state?.selectedRatePlans?.filter((rp) => {
        if (rp?.id !== rmRpId) {
          return rp;
        } else {
          console.log(rp, "=== else ===");
        }
      });
      let arr = [];
      for (let i = 1; i <= newRp?.roomCount; i++) {
        arr.push(newRp);
      }
      return {
        ...state,
        selectedRatePlans: [...filteredRps, ...arr],
      };
    }

    case hotelInfoTypes.DESELECT_RP: {
      let array = [...state.selectedRatePlans];
      for (let i = 0; i < array.length; i++) {
        if (array[i]["id"] === payload) {
          array.splice(i, 1);
          break;
        }
      }
      return {
        ...state,
        selectedRatePlans: [...array],
      };
    }

    case hotelInfoTypes.EDIT_RP: {
      let arr = [...state.selectedRatePlans];
      let updatedArr = arr.map((rp) => {
        if (rp?.id === payload?.id && rp?.idx === payload?.idx) {
          return { ...rp, ...payload };
        }
        return rp;
      });
      return { ...state, selectedRatePlans: [...updatedArr] };
    }

    case hotelInfoTypes.SET_TAXES: {
      return { ...state, taxes: [...payload] };
    }

    case hotelInfoTypes.RESET_RP: {
      var arr = [...state.selectedRatePlans];
      let newArr = arr.filter((rm) => rm?.id !== payload?.id);
      return {
        ...state,
        selectedRatePlans: [...newArr],
      };
    }
    case hotelInfoTypes.SET_COUPON_CODE: {
      const { PromotionsExtraConfigs = [] } = payload;
      const codes =
        PromotionsExtraConfigs?.filter(
          (pr) => pr?.type === "coupon_code"
        )?.[0] || {};
      let name = codes?.value?.[0] || "";
      return {
        ...state,
        couponCode: payload,
        couponName: name,
      };
    }

    case hotelInfoTypes.RESET_ROOM: {
      var arr = [...state.selectedRooms];
      var adnArr = [...state.selectedAddons];
      let newArr = arr.filter((rm) => rm?.id !== payload?.id);
      let newAdnArray = adnArr.filter((rm) => rm?.hotelRoomId !== payload?.id);
      if (payload?.resetAdn) {
        return {
          ...state,
          selectedRooms: [...newArr],
          selectedAddons: [...newAdnArray],
        };
      }
      return {
        ...state,
        selectedRooms: [...newArr],
      };
    }

    case hotelInfoTypes.SET_ROOM: {
      var arr = [...state.selectedRooms];

      let newArr =
        arr.filter(
          (rm) => rm?.id !== payload?.id || rm?.idx !== payload?.idx
        ) || [];

      newArr = [...newArr, payload];
      return { ...state, selectedRooms: newArr };
    }

    case hotelInfoTypes.DESET_ROOM: {
      const newArr = [...payload];
      return { ...state, selectedRooms: newArr };
    }

    case hotelInfoTypes.DESELECT_ROOM: {
      let arr = state.selectedRooms || [];

      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id === payload.id) {
          arr.splice(i, 1);
          break;
        }
      }
      let roomSelected = arr?.some((rm) => rm?.id === payload?.id);

      let roomAddons = state.selectedAddons.filter(
        (adn) => adn?.hotelRoomId !== payload.id
      );

      if (!roomSelected) {
        return {
          ...state,
          selectedRooms: arr,
          selectedAddons: [...roomAddons],
        };
      }

      if (arr.length === 0)
        return { ...state, selectedRooms: arr, selectedAddons: [] };
      return { ...state, selectedRooms: [...arr] };
    }

    case hotelInfoTypes.SELECT_ADDON: {
      let arr = [];
      arr.push(payload);
      return { ...state, selectedAddons: [...state.selectedAddons, ...arr] };
    }

    case hotelInfoTypes.SET_HOURLY_DATE: {
      return { ...state, hourlyDate: payload };
    }

    case hotelInfoTypes.DESELECT_ADDON: {
      const arr = state.selectedAddons;
      for (let i = 0; i < arr.length; i++) {
        if (payload?.Addon?.category === "person") {
          if (
            parseFloat(arr[i].price) === parseFloat(payload.price) &&
            arr[i].hotelRoomId === payload.hotelRoomId
          ) {
            arr.splice(i, 1);
            break;
          }
        } else if (arr[i].id === payload.id) {
          arr.splice(i, 1);
          break;
        }
      }
      return { ...state, selectedAddons: [...arr] };
    }

    case hotelInfoTypes.ADD_HOTELADDON: {
      let arr = [];
      arr.push(payload);
      return {
        ...state,
        selectedHotelAddons: [...state.selectedHotelAddons, ...arr],
      };
    }

    case hotelInfoTypes.REMOVE_HOTELADDON: {
      const arr = [...state.selectedHotelAddons];

      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id === payload.id) {
          arr.splice(i, 1);
          break;
        }
      }
      return { ...state, selectedHotelAddons: [...arr] };
    }

    case hotelInfoTypes.TOTAL_PEOPLE: {
      return { ...state, totalPeople: payload };
    }

    case hotelInfoTypes.EMPTY_ROOM: {
      return { ...state, selectedRooms: [] };
    }

    case hotelInfoTypes.TOTAL_ADULT: {
      return { ...state, totalAdult: payload };
    }

    case hotelInfoTypes.TOTAL_CHILD: {
      return { ...state, totalChild: payload };
    }

    case hotelInfoTypes.TOTAL_INFANT: {
      return { ...state, totalInfant: payload };
    }

    case hotelInfoTypes.SET_DATE: {
      return { ...state, dateRange: payload };
    }

    case hotelInfoTypes.DATE_COUNT: {
      return { ...state, dateCount: payload };
    }

    case hotelInfoTypes.RESET: {
      return {
        ...state,
        selectedRooms: [],
        selectedAddons: [],
        selectedHotelAddons: [],
        selectedRatePlans: [],
        updatedRoomCount: {},
        couponCode: {},
      };
    }

    case hotelInfoTypes.ADD_RATING: {
      return { ...state, rating: payload };
    }

    case hotelInfoTypes.ADD_REVIEW: {
      return { ...state, review: payload };
    }

    case hotelInfoTypes.PENDING_REASONS: {
      return { ...state, pendingReasons: payload };
    }

    case hotelInfoTypes.SET_INSTA_LINKS: {
      return { ...state, instaLinks: payload };
    }

    case hotelInfoTypes.SET_PROMOTION: {
      return { ...state, promotions: payload };
    }

    case hotelInfoTypes.GET_FAQ: {
      return { ...state, faqs: payload };
    }

    case hotelInfoTypes.GET_POLICY: {
      return { ...state, policies: payload };
    }

    case hotelInfoTypes.SET_PAYMENTS: {
      return { ...state, payments: payload };
    }

    case hotelInfoTypes.SET_LIVE_PRICE: {
      return { ...state, livePricing: payload };
    }

    case hotelInfoTypes.SET_UPDATED_ROOM_COUNT: {
      let obj = {
        ...state.updatedRoomCount,
        [payload?.id]: payload?.value,
      };
      return {
        ...state,
        updatedRoomCount: obj,
      };
    }

    case hotelInfoTypes.BNPL_CONFIG: {
      return { ...state, bnplConfig: payload };
    }

    case hotelInfoTypes.GET_SCRIPT_TAG_ID: {
      return { ...state, scriptTagId: payload };
    }

    case hotelInfoTypes.SET_RETARGETING: {
      return { ...state, Retargeting: payload };
    }

    case hotelInfoTypes.CALENDAR_PRICE_DISPLAY: {
      const [month, data] = Object.entries(payload)[0];
      return {
        ...state,
        CalendarPrices: {
          ...state.CalendarPrices,
          [month]: data,
        },
      };    
    }

    default: {
      return { ...state };
    }
  }
};
