import React from "react";
import "./styles/ais.css";
import useWindowSize from "../../utils/useWindowSize";

const useCases = [
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
      >
        <path
          d="M0 21.9078V23.6651C0 24.1181 0.367281 24.4854 0.820312 24.4854C1.27334 24.4854 1.64062 24.1181 1.64062 23.6651V22.7281H26.3594V23.6651C26.3594 24.1181 26.7267 24.4854 27.1797 24.4854C27.6327 24.4854 28 24.1181 28 23.6651V21.9078V19.2135H0V21.9078Z"
          fill="#0E73EC"
        />
        <path
          d="M24.9831 12.301H3.01689C1.35341 12.301 0 13.6544 0 15.318V17.5729H28V15.318C28 13.6544 26.6466 12.301 24.9831 12.301Z"
          fill="#0E73EC"
        />
        <path
          d="M10.9247 8.6698C10.1658 8.6698 9.54834 9.28717 9.54834 10.0461V10.6604H18.4515V10.0461C18.4515 9.28717 17.8341 8.6698 17.0752 8.6698H10.9247Z"
          fill="#0E73EC"
        />
        <path
          d="M7.90781 10.046C7.90781 8.38248 9.26121 7.02913 10.9248 7.02913H17.0753C18.7389 7.02913 20.0922 8.38248 20.0922 10.046V10.6603H24.4855V6.53142C24.4855 4.86788 23.1321 3.51453 21.4685 3.51453H6.53154C4.868 3.51453 3.51465 4.86788 3.51465 6.53142V10.6603H7.90781V10.046Z"
          fill="#0E73EC"
        />
      </svg>
    ),
    title: "Room Bookings",
    description:
      "Guests can check availability and book rooms instantly through AI-powered assistance, ensuring a smooth and seamless reservation process.",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
      >
        <path
          d="M17.3911 2.91051C16.5634 0.696496 13.4366 0.696495 12.6089 2.91051L10.196 9.36438C10.1882 9.38533 10.1706 9.39773 10.1527 9.39996L3.48756 10.2357C1.26676 10.5141 0.459247 13.3075 2.16871 14.7364L7.0535 18.8195C7.06968 18.833 7.07808 18.8562 7.0723 18.8795L5.40488 25.5695C4.86274 27.7446 7.18924 29.5324 9.15034 28.4084L14.975 25.0699C14.9906 25.0609 15.0094 25.0609 15.025 25.0699L20.8496 28.4084C22.8107 29.5324 25.1372 27.7446 24.5951 25.5695L22.9276 18.8795C22.9219 18.8562 22.9302 18.833 22.9465 18.8195L27.8312 14.7364C29.5407 13.3075 28.7332 10.5141 26.5124 10.2357L19.8472 9.39996C19.8294 9.39773 19.8117 9.38533 19.804 9.36438L17.3911 2.91051Z"
          fill="#0E73EC"
        />
      </svg>
    ),
    title: "Special Requests",
    description:
      "From early check-ins to extra amenities, AI handles guest preferences effortlessly, improving satisfaction without manual intervention.",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
      >
        <g clip-path="url(#clip0_330_122265)">
          <path
            d="M17.1413 19.0734C16.853 19.3615 16.4968 19.5817 16.1107 19.7104L12.3817 20.9534C12.1126 21.0431 11.832 21.0887 11.548 21.0887C10.8437 21.0887 10.1815 20.8144 9.6835 20.3164C8.9727 19.6054 8.72871 18.5716 9.04658 17.6181L10.2896 13.8893C10.4182 13.5031 10.6385 13.1466 10.9265 12.8586L18.9805 4.80469H3.22266C1.44568 4.80469 0 6.25037 0 8.02734V26.7773C0 28.5543 1.44568 30 3.22266 30H21.9727C23.7496 30 25.1953 28.5543 25.1953 26.7773V11.0195L17.1413 19.0734Z"
            fill="#0E73EC"
          />
          <path
            d="M12.1694 14.1017C12.0729 14.1982 12.0002 14.3158 11.9571 14.4452L10.7141 18.174C10.6088 18.4899 10.691 18.838 10.9264 19.0735C11.1618 19.3089 11.51 19.3911 11.8258 19.2857L15.5547 18.0428C15.6842 17.9997 15.8017 17.927 15.8982 17.8305L26.8778 6.85104L23.1489 3.12219L12.1694 14.1017Z"
            fill="#0E73EC"
          />
          <path
            d="M27.9138 0.429038C27.3418 -0.143013 26.4143 -0.143013 25.8422 0.429038L24.3921 1.87917L28.121 5.60808L29.5711 4.15794C30.1432 3.58589 30.1432 2.65841 29.5711 2.08636L27.9138 0.429038Z"
            fill="#0E73EC"
          />
        </g>
        <defs>
          <clipPath id="clip0_330_122265">
            <rect width="30" height="30" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    title: "Booking Modifications",
    description:
      "Guests can update their reservation dates, room type, or personal details instantly without waiting for hotel staff.",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
      >
        <path
          d="M3.1875 28.3333C3.19122 28.9897 3.45361 29.6181 3.91774 30.0823C4.38187 30.5464 5.0103 30.8088 5.66667 30.8125H28.3333C28.9897 30.8088 29.6181 30.5464 30.0823 30.0823C30.5464 29.6181 30.8088 28.9897 30.8125 28.3333V13.8125H3.1875V28.3333ZM12.75 19.1675C12.6205 18.9678 12.5618 18.7304 12.5834 18.4934C12.6049 18.2564 12.7053 18.0335 12.8687 17.8604C13.032 17.6873 13.2487 17.574 13.484 17.5387C13.7194 17.5034 13.9598 17.5482 14.1667 17.6658L17 20.4992L19.8333 17.6658C20.0402 17.5482 20.2806 17.5034 20.516 17.5387C20.7513 17.574 20.968 17.6873 21.1313 17.8604C21.2947 18.0335 21.3951 18.2564 21.4166 18.4934C21.4382 18.7304 21.3795 18.9678 21.25 19.1675L18.4167 22.0008L21.25 24.8342C21.449 25.0334 21.5607 25.3034 21.5607 25.585C21.5607 25.8666 21.449 26.1366 21.25 26.3358C21.0508 26.5348 20.7807 26.6466 20.4992 26.6466C20.2176 26.6466 19.9476 26.5348 19.7483 26.3358L16.915 23.5025L14.0817 26.3358C13.8824 26.5348 13.6124 26.6466 13.3308 26.6466C13.0493 26.6466 12.7792 26.5348 12.58 26.3358C12.381 26.1366 12.2693 25.8666 12.2693 25.585C12.2693 25.3034 12.381 25.0334 12.58 24.8342L15.4133 22.0008L12.75 19.1675ZM30.8125 7.08333V11.6875H3.1875V7.08333C3.19122 6.42696 3.45361 5.79854 3.91774 5.33441C4.38187 4.87028 5.0103 4.60789 5.66667 4.60417H8.85417V4.25C8.85417 3.96821 8.96611 3.69796 9.16537 3.4987C9.36462 3.29944 9.63487 3.1875 9.91667 3.1875C10.1985 3.1875 10.4687 3.29944 10.668 3.4987C10.8672 3.69796 10.9792 3.96821 10.9792 4.25V4.60417H23.0208V4.25C23.0208 3.96821 23.1328 3.69796 23.332 3.4987C23.5313 3.29944 23.8015 3.1875 24.0833 3.1875C24.3651 3.1875 24.6354 3.29944 24.8346 3.4987C25.0339 3.69796 25.1458 3.96821 25.1458 4.25V4.60417H28.3333C28.9897 4.60789 29.6181 4.87028 30.0823 5.33441C30.5464 5.79854 30.8088 6.42696 30.8125 7.08333Z"
          fill="#0E73EC"
        />
      </svg>
    ),
    title: "Cancellations & Refunds",
    description:
      "AI simplifies the cancellation process and provides real-time refund status updates, reducing friction and support load.",
  },
];

const AIS = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 768;
  return (
    <div style={{ marginTop: "8rem" }} className="containerLanding">
      <div>
        <h1
          style={{ textAlign: "center", fontSize: "48px" }}
          className="text-dark ais_sec_title"
        >
          AI Concierge: {isMobile && <br />} Smart, Fast, and{" "}
          <span className="gradient_app">Always Available</span>
        </h1>
        <p className="text-dark ais_sec_desc" style={{ textAlign: "center" }}>
          Transform guest interactions with AI-powered Voice Agents that handle
          bookings, modifications, and <br /> inquiries instantly—enhancing
          service efficiency and boosting engagement.
        </p>
      </div>

      <h1 style={{ textAlign: "center", fontSize: "20px", margin: "48px 0" }}>
        USE CASES
      </h1>

      <div className="ais_card_container">
        {useCases.map((useCase, index) => (
          <div className="ais_card">
            <div className="ais_card_icon">{useCase.icon}</div>
            <h1 className="ais_card_title">{useCase.title}</h1>
            <p className="ais_card_description">{useCase.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AIS;
