import React, { useState } from "react";
import "./styles/hero.css";
import chevronIcon from "../../assets/images/landing/chevronRightRound.svg";
import Button from "./Button";
import hotel from "./svgs/hotel.jpg";
import "./test.css";
import BookingModal from "./bookingModal";
import { CalendlyLink } from "./breadcrumbs";

const itesm = [
  {
    name: "Bookings",
    value: "34",
    growth: "44%",
  },
  {
    name: "Website Views",
    value: "1.5K",
    growth: "60%",
  },
  {
    name: "Sales",
    value: "1.2L",
    growth: "%",
  },
];

const Hero = ({ content }) => {
  const [modal, setModal] = useState(false);

  return (
    <div className="landing-container">
      <div className="background-image"></div>

      {/* Main Content Container */}
      <div className="main-container">
        {/* Main Content */}
        <div className="content">
          {content}
          <Button
            onClick={() => {
              // setModal(true);
               window.location.href = CalendlyLink;
            }}
            className="demo-button"
          >
            <span>Book a Demo</span>
            <img src={chevronIcon} alt="" />
            {/* <ArrowRight /> */}
          </Button>
        </div>
      </div>
      {modal && <BookingModal onClose={() => setModal(false)} />}
    </div>
  );
};

export default Hero;
