import React from "react";
import "./landing.css";

const Button = ({ children, className, ...rest }) => {
  return (
    <button
      style={{ cursor: "pointer" }}
      className={`lbutton ${className}`}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
