import React from 'react';
import styles from './Pagination.module.css';

const Pagination = ({ 
  currentPage, 
  totalPages, 
  onPageChange 
}) => {
  const currentPageNum = parseInt(currentPage, 10);
  const totalPagesNum = parseInt(totalPages, 10);
  
  const getPageNumbers = () => {
    const pages = [];
    
    pages.push(1);
    
    if (currentPageNum > 3) {
      pages.push('...');
    }
    
    for (let i = Math.max(2, currentPageNum - 1); i <= Math.min(totalPagesNum - 1, currentPageNum + 1); i++) {
      if (i > 1 && i < totalPagesNum) {
        pages.push(i);
      }
    }
    
    if (currentPageNum < totalPagesNum - 2) {
      pages.push('...');
    }
    
    if (totalPagesNum > 1) {
      pages.push(totalPagesNum);
    }
    
    return pages;
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPagesNum && page !== currentPageNum) {
      onPageChange({page});
    }
  };

  return (
    <div className={styles.paginationContainer}>
      <button 
        className={styles.paginationArrow} 
        onClick={() => handlePageChange(currentPageNum - 1)}
        disabled={currentPageNum === 1}
      >
        <svg 
          width="16" 
          height="16" 
          viewBox="0 0 24 24" 
          fill="none" 
          xmlns="http://www.w3.org/2000/svg"
          className={styles.arrowIcon}
        >
          <path 
            d="M19 12H5M5 12L12 19M5 12L12 5" 
            stroke="currentColor" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"
          />
        </svg>
        Previous
      </button>
      
      <div className={styles.pageNumbers}>
        {getPageNumbers().map((page, index) => (
          <React.Fragment key={index}>
            {page === '...' ? (
              <span className={styles.ellipsis}>...</span>
            ) : (
              <button
                className={`${styles.pageNumber} ${currentPageNum === page ? styles.active : ''}`}
                onClick={() => handlePageChange(page)}
              >
                {page}
              </button>
            )}
          </React.Fragment>
        ))}
      </div>
      
      <button 
        className={styles.paginationArrow} 
        onClick={() => handlePageChange(currentPageNum + 1)}
        disabled={currentPageNum === totalPagesNum}
      >
        Next
        <svg 
          width="16" 
          height="16" 
          viewBox="0 0 24 24" 
          fill="none" 
          xmlns="http://www.w3.org/2000/svg"
          className={styles.arrowIcon}
        >
          <path 
            d="M5 12H19M19 12L12 5M19 12L12 19" 
            stroke="currentColor" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </div>
  );
};

export default Pagination;