import React from "react";
import "./styles/comparison.css";

import seoSvg from "../../assets/images/landing/svgs/seo.svg";
import fees from "../../assets/images/landing/svgs/fees.svg";
import mobile from "../../assets/images/landing/svgs/mobile.svg";
import priceai from "../../assets/images/landing/svgs/priceai.svg";
import rp from "../../assets/images/landing/svgs/rp.svg";
import gads from "../../assets/images/landing/svgs/gads.svg";
import pmgs from "../../assets/images/landing/svgs/pms.svg";
import aie from "../../assets/images/landing/svgs/aie.svg";
import promo from "../../assets/images/landing/svgs/promo.svg";
import ais from "../../assets/images/landing/svgs/ais.svg";
import aic from "../../assets/images/landing/svgs/aic.svg";
import aiv from "../../assets/images/landing/svgs/aiv.svg";

import hotelzify from "../../assets/images/landing/logo.svg";

const featuresWithIcons = [
  {
    icon: seoSvg,
    title: "Website SEO Score of 90+",
  },
  {
    icon: mobile,
    title: "Mobile friendly website",
  },
  {
    icon: priceai,
    title: "Price Intelligence",
  },
  {
    icon: fees,
    title: "No upfront fees",
  },
  {
    icon: rp,
    title: "Rate parity",
  },
  {
    icon: gads,
    title: "Google Hotel Ads",
  },
  {
    icon: pmgs,
    title: "Integration with 40+ PMS",
  },
  {
    icon: aie,
    title: "AI Ads Engine",
  },
  {
    icon: promo,
    title: "Promotion Engine",
  },
  {
    icon: ais,
    title: "AI SEO",
  },
  {
    icon: aic,
    title: "AI Chatbot",
  },
  {
    icon: aiv,
    title: "AI Voicebot",
  },
];

const ComparisonTable = () => {
  return (
    <div className="comparison-section">
      <h1 className="text-dark testimonials-title onestfont">
        Still not <span className="gradient_app onestfont">convinced?</span>
      </h1>
      <div className="containerLanding comparison-table-container">
        <table className="comparison-table">
          <thead className="comparison-header">
            <tr>
              <th className="features-header">
                <div className="features_txt">FEATURES</div>
              </th>
              <th className="brand-header">
                <img src={hotelzify} alt="" />
              </th>
              <th style={{ color: "#757B77" }} className="others-header">
                OTHERS
              </th>
            </tr>
          </thead>
          <tbody className="comparison-body">
            {featuresWithIcons.map((feature, index) => (
              <tr key={index}>
                <td className="feature-comparison interfont text-dark">
                  <img src={feature.icon} alt="" />
                  {feature.title}
                </td>
                <td className="check table_icon_d tick_td">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="38"
                    height="37"
                    viewBox="0 0 38 37"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_268_221436)">
                      <path
                        d="M19 0C8.79869 0 0.5 8.29869 0.5 18.5C0.5 28.7013 8.79869 37 19 37C29.2013 37 37.5 28.7013 37.5 18.5C37.5 8.29869 29.2013 0 19 0Z"
                        fill="#2196F3"
                      />
                      <path
                        d="M28.3764 14.5796L18.3555 24.6002C18.0549 24.9008 17.6602 25.0521 17.2656 25.0521C16.871 25.0521 16.4763 24.9008 16.1757 24.6002L11.1654 19.5899C10.5624 18.9872 10.5624 18.0127 11.1654 17.4101C11.768 16.8071 12.7422 16.8071 13.3452 17.4101L17.2656 21.3305L26.1966 12.3997C26.7993 11.7968 27.7735 11.7968 28.3764 12.3997C28.9791 13.0024 28.9791 13.9766 28.3764 14.5796Z"
                        fill="#FAFAFA"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_268_221436">
                        <rect
                          width="37"
                          height="37"
                          fill="white"
                          transform="translate(0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </td>
                <td className="cross table_icon_d">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <path
                      d="M18 6.5L6 18.5M6 6.5L18 18.5"
                      stroke="#6B7280"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ComparisonTable;
