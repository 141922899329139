import React from "react";
import right from "../../assets/images/landing/svgs/right.svg";
import left from "../../assets/images/landing/svgs/left.svg";
import "./styles/integrations.css";

import image1 from "../../assets/images/landing/integrations/1.png";
import image2 from "../../assets/images/landing/integrations/2.png";
import image3 from "../../assets/images/landing/integrations/3.png";
import image4 from "../../assets/images/landing/integrations/4.png";
import image5 from "../../assets/images/landing/integrations/5.png";
import image6 from "../../assets/images/landing/integrations/6.png";
import image7 from "../../assets/images/landing/integrations/7.png";
import image8 from "../../assets/images/landing/integrations/8.png";
import image9 from "../../assets/images/landing/integrations/9.png";
import image10 from "../../assets/images/landing/integrations/10.png";
import image11 from "../../assets/images/landing/integrations/11.png";
import useWindowSize from "../../utils/useWindowSize";

const images = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  null,
];

const Integrations = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 768;
  return (
    <div style={{ marginTop: "7rem", marginBottom: isMobile ? "0" : "10rem" }}>
      <div
        style={{
          ...(isMobile ? {} : { display: "flex" }),
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {width >= 768 && <img  style={{width:"20%"}}  src={right} alt="" />}
        <div>
          <h1 className="text-dark integration_title">
            The Ultimate Partner for <br /> Your{" "}
            <span className="gradient_app">Hotel’s Success</span>
          </h1>
          <p
            className="text-dark integration_description"
            style={{ fontSize: "20px", textAlign: "center", marginTop: "12px" }}
          >
            Collaborating with top industry innovators to deliver{" "}
            {isMobile && <br />} effortless integrations and unmatched
            performance.
          </p>
        </div>
        {width >= 768 && <img style={{width:"20%"}} src={left} alt="" />}
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(6, auto)",
          gap: "16px",
          justifyContent: "center",
          alignItems: "center",
          margin: "4rem 4rem 0",
          // width: "max-content",
          marginTop: "4rem",
        }}
        className="integrations-container"
      >
        {images.map((image) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            {!image ? (
              <p>+ Many More</p>
            ) : (
              <img
                className="int_logo"
                src={image}
                alt=""
                style={{ width: "100%", height: "auto" }}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Integrations;
