import React from 'react';
import styles from './BlogCard.module.css';
import { format } from 'date-fns'
import hotelzifyLogo from '../../../assets/images/Hotelzify.jpg';
import { Link, useHistory } from 'react-router-dom';

const BlogPostCard = ({ 
  thumbnail, 
  category = "category", 
  title, 
  excerpt,
  slug, 
  authorImage, 
  authorName='Hotelzify', 
  createdAt, 
}) => {

  const history = useHistory();

  return (
    <div className={styles.card}>
      <div className={styles.imageContainer} onClick={()=>history.push(`/blogs/${slug}`)}>
        <img src={thumbnail} alt={title} className={styles.image} />
      </div>
      
      <div className={styles.content}>
        <div className={styles.category}>{category}</div>
        
        <h2 className={styles.title} onClick={()=>history.push(`/blogs/${slug}`)}>
            {title}
          <span className={styles.arrow}>
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 17L17 7M17 7H7M17 7V17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </span>
        </h2>
        
        <p className={styles.description}>{excerpt}</p>
      </div>
      <div className={styles.authorSection}>
          <img src={hotelzifyLogo} alt={authorName} className={styles.authorImage} />
          <div className={styles.authorInfo}>
            <div className={styles.authorName}>{authorName}</div>
            <div className={styles.date}>{format(new Date(createdAt), 'dd MMM yyyy')}</div>
          </div>
        </div>
    </div>
  );
};

export default BlogPostCard;