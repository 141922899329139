import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom/client";
import "react-phone-number-input/style.css";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "../node_modules/toastr/build/toastr.min.css";
import Home from "./Home";
import "./index.css";
import { store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { fireGtmEvent } from "./utils/googleAnalytics";
import "./i18n";
import { SentryErrorBoundary } from "./ErrorBoundry";

if (
  process.env.REACT_APP_IS_PROD === "true" &&
  !window.location.host.includes("localhost") &&
  !window.location.host.includes("dev.hotelzify.com")
) {
  Sentry.init({
    dsn: "https://e2ed10825e3b86bcd09d054ecc56c6d4@o4505484329353216.ingest.us.sentry.io/4508567072014336",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({ maskAllText: false }),
      Sentry.captureConsoleIntegration({
        levels: ["error"],
      }),
      Sentry.extraErrorDataIntegration({ depth: 10 }),
      Sentry.httpClientIntegration(),
    ],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_IS_PROD === "true" ? "Prod" : "Dev",
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));

const trackPerformance = (obj) => {
  fireGtmEvent(obj?.name, { perfomanceObj: obj });
};

function MainApp() {
  return (
    // <CacheBuster
    //   currentVersion={packageJson.version}
    //   isEnabled={true} //If false, the library is disabled.
    //   isVerboseMode={true} //If true, the library writes verbose logs to console.
    //   loadingComponent={<Loader />} //If not pass, nothing appears at the time of new version check.
    //   metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    // >
    <SentryErrorBoundary>
      <Provider store={store}>
        <Router>
          <Home />
        </Router>
      </Provider>
    </SentryErrorBoundary>
    // </CacheBuster>
  );
}
// const CacheClearedComponent = withClearCache(MainApp);
root.render(<MainApp />);
serviceWorkerRegistration.unregister();
reportWebVitals(trackPerformance);
