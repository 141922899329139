import React, { useEffect, useState } from "react";
import "./styles/testimonial.css";
import clubmahindraLogo from "../../assets/images/landing/clubmahindra.png";
import sterlingLogo from "../../assets/images/landing/sterling.png";
import subaLogo from "../../assets/images/landing/clients/suba.png";
import idHotelier from "../../assets/images/landing/clients/idho.png";
import vivek from "../../assets/images/landing/vivek.png";

const TestimonialsSection = () => {
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const [flippedCards, setFlippedCards] = useState([false, false, false]);

  // Detect touch device on component mount
  useEffect(() => {
    const touchDevice =
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0;
    setIsTouchDevice(touchDevice);
  }, []);

  // Handle tap for mobile devices with JavaScript fallback
  const handleTap = (index) => {
    if (isTouchDevice) {
      const newFlippedCards = [...flippedCards];
      newFlippedCards[index] = !newFlippedCards[index];
      setFlippedCards(newFlippedCards);
    }
  };

  return (
    <section className="testimonials-container">
      <h2 className="testimonials-title text-dark onestfont">
        Loved by Clients, Proven by{" "}
        <span className="gradient_app">Results</span>
      </h2>

      <div className="testimonials-grid">
        {/* Club Mahindra Card */}
        <div
          className={`testimonial-card-container ${
            isTouchDevice && flippedCards[0] ? "js-flipped" : ""
          }`}
          onClick={() => handleTap(0)}
        >
          <div className="testimonial-card-inner">
            {/* Front Side */}
            <div className="testimonial-card-front light-green">
              <img src={subaLogo} alt="Suba" className="client-logo" />

              <div className="stats-list">
                <div className="stat-item">
                  <span className="stat-percentage">53%</span>
                  <span className="stat-label">
                    Increase in Direct Bookings
                  </span>
                </div>
                <div className="stat-item">
                  <span className="stat-percentage">41%</span>
                  <span className="stat-label">Increase in Revenue</span>
                </div>
                <div className="stat-item">
                  <span className="stat-percentage">10X</span>
                  <span className="stat-label">ROAS</span>
                </div>
              </div>
            </div>

            {/* Back Side */}
            <div className="testimonial-card-back light-green">
              <img src={subaLogo} alt="Suba" className="client-logo" />
              <strong style={{ marginBottom: 0 }} className="text-dark">
                A Platform that is designed to put the needs of the hotelier
                first
              </strong>
              <blockquote className="testimonial-quote">
                <br />
                Their low commission structure helps us keep our acquisition
                costs down while enabling better guest services; combined with
                smart Google Ads integration that reduces wasted ad spend, an
                extremely intuitive user interface, and top-notch client
                grievance redressal, they deliver exceptional value across the
                board.
              </blockquote>
              {/* <div className="testimonial-author">
                <img src={vivek} alt="John Smith" className="author-image" />
                <div className="author-details">
                  <div className="author-name">Vivek Rao</div>
                  <div className="author-role">Manager</div>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        {/* Sterling Card with Quote */}
        <div
          className={`testimonial-card-container ${
            isTouchDevice && flippedCards[1] ? "js-flipped" : ""
          }`}
          onClick={() => handleTap(1)}
        >
          <div className="testimonial-card-inner">
            {/* Front Side */}
            <div className="testimonial-card-front light-pink">
              <img src={idHotelier} alt="ID Hotelier" className="client-logo" />
              <strong className="text-dark">
                Hotelzify Booking Engine: A Game-Changer in Hospitality
              </strong>
              <blockquote className="testimonial-quote">
                Hotelzify offers a user-friendly interface that ensures a
                seamless booking experience. Its lightning-fast engine reduces
                the time from search to booking confirmation without
                compromising reliability. Integration with Google allows users
                to find and book hotels directly through Google Search and Maps,
                enhancing convenience. Additionally, AI-driven recommendations
                personalize accommodations based on user preferences and past
                behavior.
              </blockquote>
              {/* <div className="testimonial-author">
                <img src={vivek} alt="Vivek Rao" className="author-image" />
                <div className="author-details">
                  <div className="author-name">Vivek Rao</div>
                  <div className="author-role">Manager</div>
                </div>
              </div> */}
              <div className="flip-prompt"></div>
            </div>
            {/* Back Side */}

            <div className="testimonial-card-back light-pink">
              <img src={idHotelier} alt="Id Hotelier" className="client-logo" />
              <div className="stats-list">
                <div className="stat-item">
                  <span className="stat-percentage">58%</span>
                  <span className="stat-label">
                    Increase in Direct Bookings
                  </span>
                </div>
                <div className="stat-item">
                  <span className="stat-percentage">32%</span>
                  <span className="stat-label">Increase in Revenue</span>
                </div>
                <div className="stat-item">
                  <span className="stat-percentage">12X</span>
                  <span className="stat-label">ROAS</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Sterling Stats Card */}
        <div
          className={`testimonial-card-container ${
            isTouchDevice && flippedCards[2] ? "js-flipped" : ""
          }`}
          onClick={() => handleTap(2)}
        >
          <div className="testimonial-card-inner">
            {/* Front Side */}
            <div className="testimonial-card-front light-purple">
              <img src={sterlingLogo} alt="Sterling" className="client-logo" />
              <div className="stats-list">
                <div className="stat-item">
                  <span className="stat-percentage">67%</span>
                  <span className="stat-label">
                    Increase in Direct Bookings
                  </span>
                </div>
                <div className="stat-item">
                  <span className="stat-percentage">29%</span>
                  <span className="stat-label">Increase in Revenue</span>
                </div>
                <div className="stat-item">
                  <span className="stat-percentage">15X</span>
                  <span className="stat-label">ROAS</span>
                </div>
              </div>
            </div>

            {/* Back Side */}
            <div className="testimonial-card-back light-purple">
              <img src={sterlingLogo} alt="Sterling" className="client-logo" />
              <strong className="text-dark">
                Hotelzify The Best Booking Engine
              </strong>
              <blockquote className="testimonial-quote">
                Hotelzify is the best tool to get direct bookings. There ad
                engine optimizes our ads in such a manner that the cost is
                lowest.
              </blockquote>
              {/* <div className="testimonial-author">
                <img src={vivek} alt="Sarah Johnson" className="author-image" />
                <div className="author-details">
                  <div className="author-name">Vivek Rao</div>
                  <div className="author-role">Manager</div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;
