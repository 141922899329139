import { fireGtmEvent, reportStorageError } from "../utils/googleAnalytics";

// const tokenKey = "owner";
const { stringify, parse } = JSON;

const storageService = {
  get: (tokenKey) => {
    try {
      return parse(sessionStorage.getItem(tokenKey)) ?? null;
    } catch (error) {
      reportStorageError({
        type: "get",
        error,
        key: tokenKey,
      });
    }
  },
  set: (tokenKey, value) => {
    try {
      return sessionStorage.setItem(tokenKey, stringify(value));
    } catch (error) {
      reportStorageError({
        type: "set",
        error,
        key: tokenKey,
        value,
      });
    }
  },
  remove: (tokenKey) => {
    try {
      return sessionStorage.removeItem(tokenKey);
    } catch (error) {
      reportStorageError({
        type: "remove",
        error,
        key: tokenKey,
      });
    }
  },
  clear: () => {
    try {
      return sessionStorage.clear();
    } catch (error) {
      reportStorageError({
        type: "clear",
        error,
      });
    }
  },
};

export default storageService;
