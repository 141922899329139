import { useState } from "react";

const FeatureGrid = ({ features }) => {
  return (
    <div style={{ marginTop: "82px" }} className="containerLanding">
      <h1 style={{ textAlign: "center" }} className="text-dark feature_title">
        Feature <span className="gradient_app">Highlights</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="42"
          height="42"
          viewBox="0 0 42 42"
          fill="none"
        >
          <path
            d="M33.3644 23.2557C22.4495 25.0506 20.509 27.3306 19.0537 40.6225C19.0052 41.0591 18.3746 41.0591 18.3261 40.6225C16.8707 27.3306 14.9303 25.0991 4.01543 23.2557C3.57884 23.2072 3.57884 22.5766 4.01543 22.5281C14.9303 20.7332 16.8707 18.5017 18.3261 5.20977C18.3746 4.77318 19.0052 4.77318 19.0537 5.20977C20.509 18.5017 22.4495 20.6847 33.3644 22.5281C33.7525 22.5766 33.7525 23.1587 33.3644 23.2557Z"
            fill="#B7CBFF"
          />
          <path
            d="M38.0213 7.92643C34.6256 8.60558 33.6554 9.72133 33.0733 13.6992C33.0247 14.1358 32.3941 14.1358 32.3456 13.6992C31.7635 9.72133 30.7933 8.60558 27.3975 7.87792C27.0094 7.7809 27.0094 7.24728 27.3975 7.15026C30.7447 6.47111 31.7635 5.35537 32.3456 1.3775C32.3941 0.9409 33.0247 0.9409 33.0733 1.3775C33.6554 5.35537 34.6256 6.47111 38.0213 7.19877C38.4094 7.29579 38.4094 7.87792 38.0213 7.92643Z"
            fill="#B7CBFF"
          />
        </svg>
      </h1>
      <div className="feature-grid">
        {features.map((feature, index) => (
          <div key={feature.id} className="feature-item">
            <div className="feature-content">
              <h2 className="feature-title">{feature.title}</h2>
              <p className="feature-description">{feature.description}</p>
            </div>
            <div className={`feature-image feature-bg-${(index % 4) + 1}`}>
              <img src={feature.image} alt={`${feature.title} feature`} />
            </div>
          </div>
        ))}
      </div>

      <style>{`
        .feature-grid {
        margin-top: 80px;
          display: flex;
          flex-direction: column;
          gap: 80px;
        }

        .feature-item {
          display: flex;
          align-items: center;
          gap: 60px;
        }

        .feature-item:nth-child(even) {
          flex-direction: row-reverse;
        }

        .feature-content {
          flex: 1;
          max-width: 50%;
        }

        .feature-title {
          font-size: 40px;
          font-weight: 600;
          color: #000;
          margin-bottom: 16px;
        }

        .feature-description {
          font-size: 18px;
          color: #666;
          margin-bottom: 20px;
          line-height: 1.6;
        }

         .feature_title {
            font-size: 48px;
          }

        .feature-cta {
          display: inline-block;
          padding: 8px 16px;
          background-color: #f0f0f0;
          border-radius: 4px;
          text-decoration: none;
          color: #333;
          font-weight: 500;
          font-size: 14px;
        }

        .feature-image {
          flex: 1;
          max-width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px;
          border-radius: 12px;
        }

        .feature-image img {
          max-width: 100%;
          height: auto;
          border-radius: 8px;
        }


        @media (max-width: 768px) {
          .feature-item, 
          .feature-item:nth-child(even) {
            flex-direction: column;
            gap: 30px;
          }

          .feature-content,
          .feature-image {
            max-width: 100%;
          }

          .feature-title {
            font-size: 24px;
            text-wrap: wrap
          }

          .feature-description {
            font-size: 16px;
          }

          .feature_title {
            font-size: 32px;
          }
        }
      `}</style>
    </div>
  );
};

export default FeatureGrid;
