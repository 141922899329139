import React, { useState } from "react";
import "./styles/faq.css";
import useWindowSize from "../../utils/useWindowSize";

const questions = [
  {
    question: "Why should I have my own website?",
    answer:
      "Having your own website allows you to bypass commissions lost to online travel agencies (OTAs) like Booking, MMT, and Goibibo, enabling guests to book directly with you and promoting your own branding.",
  },
  {
    question: "What do I require to create my website?",
    answer:
      "Simply download the Hotelzify app onto your mobile phone and follow four straightforward steps to set up your website for accepting bookings.",
  },
  {
    question: "How are online payments processed?",
    answer:
      "Payments are facilitated through International payment and are automatically settled into your account on the check-in date. You just need to input your bank details into the app, and settlements will be handled seamlessly.",
  },
  {
    question: "How can I attract bookings to my website?",
    answer:
      "As Google Hotel Partners, Hotelzify sends your property prices and inventory to Google, improving your property's ranking with an official site tag. Additionally, you can leverage social media and WhatsApp to drive more traffic and bookings to your website.",
  },
  {
    question: "What initial investment is required?",
    answer:
      "No initial investment is necessary. Hotelzify operates on a pay-per-booking model, meaning you're only charged when you receive bookings. However, if you desire a custom website, feel free to reach out to us for assistance.",
  },
  {
    question: "Is there support available for technical issues?",
    answer:
      "Yes, Hotelzify provides comprehensive technical support to assist you with any issues or questions you may encounter. Our team is readily available to help you navigate the website management process smoothly.     ",
  },
];

const FaqAccordion = () => {
  const [show, setShow] = useState(null);
  const { width } = useWindowSize();

  const isMobile = width <= 768;

  const widthIcon = isMobile ? 15 : 24;

  return (
    <div>
      <div style={{ marginBottom: "0" }} className="accordion containerLanding">
        {questions.map((question, index) => (
          <div
            style={{ ...(show === index ? { background: "#EAF9FF" } : {}) }}
            className="accordion-item"
          >
            <button
              onClick={() => {
                if (show === index) {
                  setShow(null);
                } else {
                  setShow(index);
                }
              }}
              className="accordion-header"
              style={{
                ...(show === index
                  ? { background: "#EAF9FF" }
                  : { background: "#fff" }),
              }}
              aria-expanded="false"
            >
              {question.question}
              {show === index ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={widthIcon}
                  height={widthIcon}
                  viewBox="0 0 26 26"
                  fill="none"
                >
                  <path
                    d="M1.55994 14.56H24.4398C25.3016 14.56 26 13.8615 26 12.9996C26 12.1379 25.3018 11.4395 24.4398 11.4395H1.55994C0.698428 11.4397 0 12.1381 0 12.9998C0 13.8615 0.698428 14.56 1.55994 14.56Z"
                    fill="#302F2F"
                  />
                </svg>
              ) : (
                <span className="icon_acc" aria-hidden="true">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={widthIcon}
                    height={widthIcon}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_268_221016)">
                      <path
                        d="M22.7999 10.8H13.2001V1.19993C13.2001 0.537671 12.6624 0 11.9999 0C11.3377 0 10.8 0.537671 10.8 1.19993V10.8H1.19993C0.537671 10.8 0 11.3377 0 11.9999C0 12.6624 0.537671 13.2001 1.19993 13.2001H10.8V22.7999C10.8 23.4624 11.3377 24.0001 11.9999 24.0001C12.6624 24.0001 13.2001 23.4624 13.2001 22.7999V13.2001H22.7999C23.4624 13.2001 24.0001 12.6624 24.0001 11.9999C24.0001 11.3377 23.4624 10.8 22.7999 10.8Z"
                        fill="#302F2F"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_268_221016">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              )}
            </button>
            {show === index && (
              <div className="accordion-content">
                <p>{question.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FaqAccordion;
