import React from "react";
import "./landing.css";
import Navbar from "./navbar/navbar";
import Hero from "./Hero";
import FooterHero from "./footerCta";
import Footer from "./footer";
import AboutSection from "./AboutSection";
import FeatureGrid from "./featuresGrid";
import image1 from "./features/ai/1.png";
import image2 from "./features/ai/2.png";
import image3 from "./features/ai/3.png";
import image4 from "./features/ai/4.png";
import image5 from "./features/ai/5.png";
import AIS from "./ais";
import useWindowSize from "../../utils/useWindowSize";

const features = [
  {
    id: 1,
    title: "Omnichannel Integration",
    description:
      "Guests can interact via (call) Voice, WhatsApp, website chat, email, Messenger, and more.",
    image: image1,
  },
  {
    id: 2,
    title: "Dynamic Room Availability",
    description:
      "Real-time updates on bookings and pricing, accessible across platforms.",
    image: image2,
  },
  {
    id: 3,
    title: "Personalized Conversations",
    description:
      "Responses tailored to guest history and preferences, enhancing engagement.",
    image: image3,
  },
  {
    id: 4,
    title: "Special Request Handling",
    description: "Capture and process special requests during interactions.",
    image: image4,
  },
  {
    id: 5,
    title: "Re-engagement & Follow-ups",
    description:
      "Automatically follow up with users who abandon conversations or bookings.",
    image: image5,
  },
];

const AiSales = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 768;
  return (
    <div className="">
      <Navbar />
      <Hero
        content={
          <>
            <h3 className="product_title_l">AI Sales Concierge</h3>
            <h1
              style={{ textWrap: !isMobile ? "nowrap" : "wrap" }}
              className="text-dark onestfont"
            >
              Transform{" "}
              <span className="gradient_app">Guest Communication</span>
            </h1>
            <h1 className="text-dark onestfont">with AI Voice Concierge</h1>
            <p>
              Provide natural, real-time voice assistance that boosts guest
              engagement, automates inquiries, and <br /> increases hotel
              bookings seamlessly.
            </p>
          </>
        }
      />

      <AIS />

      <FeatureGrid features={features} />

      <FooterHero />
      <Footer />
    </div>
  );
};

export default AiSales;
