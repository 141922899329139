import React, { useState, useEffect } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import * as blogService from '../../Services/blogService';
import styles from './BlogDetailPage.module.css';
import Navbar from '../../components/landing/navbar/navbar';
import Footer from '../../components/landing/footer';
import Hero from '../../components/landing/footerCta';
import { format } from 'date-fns';
import hotelzifyLogo from '../../assets/images/Hotelzify.jpg';
import BlogPostCard from '../../components/Blog/BlogCard/BlogCard';

const BlogDetailPage = () => {
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);
  const [relatedPosts, setRelatedPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogDetail = async () => {
      if (!slug) return;
      
      try {
        setLoading(true);
        console.log({ slug })
        const response = await blogService.getBlogBySlug(slug);
        console.log(response);
        if (response.status === 200) {
          setBlog(response.data);
          
          // After successfully loading the blog, fetch related posts
          if (response.data?.categoryId) {
            try {
              const relatedResponse = await blogService.getAllBlogs({
                page: 1,
                limit: 5,
                sortBy: 'recent',
                categoryId: null,
              });
              // Filter out the current post from related posts
              const filtered = (relatedResponse.data?.blogs || [])
                .filter(post => post.slug !== slug)
                .slice(0, 3);
              setRelatedPosts(filtered);
            } catch (err) {
              console.error('Error fetching related posts:', err);
              setRelatedPosts([]);
            }
          }
        }
        
        setLoading(false);
      } catch (err) {
        console.error('Error fetching blog details:', err);
        setError('Failed to load blog post. Please try again later.');
        setLoading(false);
      }
    };

    window.scrollTo(0, 0);
    fetchBlogDetail();
  }, [slug]);

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  // Estimate reading time based on word count
  const calculateReadingTime = (content) => {
    if (!content) return '1 min read';
    
    // Strip HTML tags and count words
    const text = content.replace(/<[^>]*>?/gm, '');
    const words = text.split(/\s+/).length;
    
    // Average reading speed: 200 words per minute
    const readingTime = Math.ceil(words / 200);
    return `${readingTime} min read`;
  };

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.loadingSpinner}></div>
        <p>Loading article...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.errorContainer}>
        <svg className={styles.errorIcon} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
        </svg>
        <p className={styles.errorMessage}>{error}</p>
        <Link to="/blogs" className={`${styles.button} ${styles.secondaryButton}`}>
          Back to Blog List
        </Link>
      </div>
    );
  }

  if (!blog) {
    return (
      <div className={styles.errorContainer}>
        <svg className={styles.errorIcon} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
        </svg>
        <p className={styles.errorMessage}>Blog post not found</p>
        <Link to="/blogs" className={`${styles.button} ${styles.secondaryButton}`}>
          Back to Blog List
        </Link>
      </div>
    );
  }

  return (
    <>
    <Navbar/>
    <div className={styles.container}>
      <Link to="/blogs" className={styles.backLink}>
        <svg className={styles.backIcon} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path>
        </svg>
        Back to all articles
      </Link>

      <article>
        {/* Article Header */}
        <header className={styles.meta}>
          {blog.category && (
            <div className={styles.category}>
              {blog?.category?.title}
            </div>
          )}
          
          <h1 className={styles.title}>{blog.title}</h1>
          
          <div className={styles.infoBar}>
            <div className={styles.authorContainer}>
              <img 
                src={hotelzifyLogo} 
                alt={'Hotelzify'} 
                className={styles.avatar}
              />
              <div className={styles.authorInfo}>
                <span className={styles.authorName}>{'Hotelzify'}</span>
                <span className={styles.publishDate}>{format(new Date(blog?.createdAt), 'dd MMM yyyy')}</span>
              </div>
            </div>
            
            <div className={styles.readingTime}>
              <svg className={styles.clockIcon} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
              </svg>
              {calculateReadingTime(blog.body)}
            </div>
          </div>
        </header>
        
        {blog.image && (
          <img 
            src={blog.image} 
            alt={blog.title} 
            className={styles.featuredImage}
          />
        )}
        
        <div className={styles.content}>
          <div 
            className={styles.contentBody}
            dangerouslySetInnerHTML={{ __html: blog.body || '' }}
          />
          
        </div>
      </article>
      
      {relatedPosts.length > 0 && (
        <section className={styles.relatedPostsSection}>
          <h2 className={styles.sectionTitle}>Related Articles</h2>
          
          <div className={styles.relatedPosts}>
            {relatedPosts.map(blog => (
              <BlogPostCard
                thumbnail = { blog?.thumbnail }
                category = {blog?.category?.title}
                title = {blog?.title}
                excerpt = {blog?.excerpt}
                slug = {blog?.slug}
                createdAt = {blog?.createdAt}
              />
            ))}
          </div>
        </section>
      )}
      
    </div>
    <Hero/>
    <Footer/>
    </>
    
  );
};

export default BlogDetailPage;