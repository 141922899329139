import React from "react";
import "./styles/partner.css";
import logo1 from "../../assets/images/landing/partners/1.png";
import logo2 from "../../assets/images/landing/partners/2.png";
import logo3 from "../../assets/images/landing/partners/3.png";

const logos = [logo1, logo2, logo3];
const PartnerSection = () => {
  return (
    <div style={{ marginTop: "6rem" }} className="containerLanding">
      <div className="partner-container">
        <h1
          style={{
            fontSize: "48px",
            fontWeight: 700,
            textAlign: "center",
            width: "100%",
          }}
          className="text-dark onestfont partner-title"
        >
          We are <span className="gradient_app onestfont">Partnered </span>
          with Top Hotel Associations
        </h1>
        <p className="text-dark">
          Join the cool kid’s club of direct bookings with Hotelzify
        </p>
        <div>
          <div className="partner-logos">
            {logos.map((logo) => (
              <img style={{ margin: "24px" }} src={logo} alt="" />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerSection;
