import React from "react";
import "./styles/faq.css";
import FaqAccordion from "./faqAccordion";
import right from "../../assets/images/landing/svgs/right.svg";
import left from "../../assets/images/landing/svgs/left.svg";
import useWindowSize from "../../utils/useWindowSize";

const FAQ = () => {
  const { width } = useWindowSize();
  return (
    <div id="lfaqs" className="">
      <div className="faq-section">
        {width >= 768 && <img style={{ height: "250px" }} src={right} alt="" />}
        <h1 className="text-dark faq-title onestfont">
          Frequently Asked{" "}
          <span className="gradient_app onestfont">Questions</span>
        </h1>

        {width >= 768 && <img style={{ height: "250px" }} src={left} alt="" />}
      </div>

      <FaqAccordion />
    </div>
  );
};

export default FAQ;
