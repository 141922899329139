import React, { useState } from "react";
import "./styles/clients.css";
import clubmahindraLogo from "../../assets/images/landing/clubmahindra.png";
import atLogo from "../../assets/images/landing/clients/at.png";
import bestLogo from "../../assets/images/landing/clients/best.png";
import cmLogo from "../../assets/images/landing/clients/cm.png";
import containerLogo from "../../assets/images/landing/clients/Container.png";
import fernLogo from "../../assets/images/landing/clients/fern.png";
import howardLogo from "../../assets/images/landing/clients/howard.png";
import lordsLogo from "../../assets/images/landing/clients/lords.png";
import oliveLogo from "../../assets/images/landing/clients/olive.png";
import radissonLogo from "../../assets/images/landing/clients/radisson.png";
import rameeLogo from "../../assets/images/landing/clients/ramee.png";
import sterlingLogo from "../../assets/images/landing/clients/sterling.png";
import strideLogo from "../../assets/images/landing/clients/stride.png";
import minimalist from "../../assets/images/landing/clients/minimalist.png";
import earth from "../../assets/images/landing/clients/earth.png";
import zone from "../../assets/images/landing/clients/zone.png";
import oyologo from "../../assets/images/landing/clients/oyo.png";
import subaLogo from "../../assets/images/landing/clients/suba.png";
import useWindowSize from "../../utils/useWindowSize";
import HotelPartnersLayout from "./clientsection";
import BookingModal from "./bookingModal";
import { CalendlyLink } from "./breadcrumbs";

const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    maxWidth: "100%",
    margin: "0 auto",
    padding: "10px",
    boxSizing: "border-box",
    overflowX: "hidden",
    marginBottom: "48px",
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gap: "1px",
    border: "1px solid #e0e0e0",
    // borderRadius: "4px",
    overflow: "hidden",
  },
  logoBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
    backgroundColor: "#fff",
    borderRight: "1px solid #e0e0e0",
    borderBottom: "1px solid #e0e0e0",
    height: "70px",
  },
  logo: {
    maxWidth: "90%",
    maxHeight: "40px",
    objectFit: "contain",
  },
  heading: {
    textAlign: "center",
    margin: "25px 0 35px",
    fontSize: "20px",
    fontWeight: "normal",
    color: "#333",
  },
  highlightText: {
    color: "#0089e0",
    fontWeight: "bold",
  },
  ctaButton: {
    display: "block",
    backgroundColor: "#3987e1",
    color: "white",
    textAlign: "center",
    padding: "15px",
    // margin: "20px 0",
    // borderRadius: "3px",
    textDecoration: "none",
    fontWeight: "bold",
    fontSize: "16px",
    width: "100%",
  },
  lastBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    // border: "1px solid #e0e0e0",
    borderRadius: "4px",
    color: "#3987e1",
    fontWeight: "bold",
    textAlign: "center",
    height: "70px",
    fontSize: "9px",
  },
};

const ClientLogosSection = () => {
  const { width } = useWindowSize();
  const [modal, setModal] = useState(false);

  if (width <= 768) {
    return (
      <div style={styles.container}>
        {/* First row of logos */}
        <div style={styles.gridContainer}>
          {[
            bestLogo,
            sterlingLogo,
            fernLogo,
            cmLogo,
            containerLogo,
            subaLogo,
            atLogo,
            lordsLogo,
          ].map((logo, index) => (
            <div key={index} style={styles.logoBox}>
              <img src={logo} alt={logo.name} style={styles.logo} />
            </div>
          ))}
        </div>

        {/* CTA Button */}
        <h2
          style={{
            textAlign: "center",
            fontSize: "24px",
            padding: "24px 8.333px",
          }}
          className="trust-title onestfont"
        >
          Trusted by <span className="gradient_app onestfont">over 13000</span>{" "}
          Hotels around the World
        </h2>
        <button
          onClick={() => {
            // setModal(true);
            window.location.href = CalendlyLink;
          }}
          href="#book-demo"
          style={styles.ctaButton}
        >
          Book a Demo
        </button>

        {/* Second row of logos */}
        <div style={styles.gridContainer}>
          {[
            oliveLogo,
            radissonLogo,
            strideLogo,
            rameeLogo,
            minimalist,
            earth,
            zone,
            // sterlingLogo,
            // sterlingLogo,
            // fernLogo,
          ].map((logo, index) => (
            <div key={index} style={styles.logoBox}>
              <img src={logo} alt={logo.name} style={styles.logo} />
            </div>
          ))}
          <div style={styles.lastBox}>This can be you</div>
        </div>
        {modal && <BookingModal onClose={() => setModal(false)} />}
      </div>
    );
  }
  return (
    <section className="client-logos-container">
      {/* Top row logos */}
      <div className="logo-grid top-grid">
        {[bestLogo, sterlingLogo, fernLogo, cmLogo].map((logo) => (
          <div className="logo_container">
            <img src={logo} alt="Best Western" className="client-logoi" />
          </div>
        ))}
      </div>

      {/* Left column logos */}

      <div className="logo-grid-content">
        <div className="">
          {[
            containerLogo,
            subaLogo,
            atLogo,
            lordsLogo,
            // lordsLogo,
            // oliveLogo,
            // clubmahindraLogo,
          ].map((logo) => (
            <div className="logo_container logo_right">
              <img src={logo} alt="Best Western" className="client-logoi" />
            </div>
          ))}
        </div>

        <div className="center-content">
          <h2 className="trust-title onestfont">
            Trusted by{" "}
            <span className="gradient_app onestfont">over 13000</span> Hotels
            <br />
            around the World
          </h2>
          <button
            onClick={() => {
              // setModal(true);
              window.location.href = CalendlyLink;
            }}
            className="demo-button-2"
          >
            Book a Demo
          </button>
        </div>

        <div className="">
          {[
            oliveLogo,
            radissonLogo,
            strideLogo,
            rameeLogo,
            // sterlingLogo,
            // strideLogo,
            // clubmahindraLogo,
          ].map((logo) => (
            <div className="logo_container logo_right">
              <img
                style={{ maxHeight: "100%" }}
                src={logo}
                alt="Best Western"
                className="client-logoi"
              />
            </div>
          ))}
        </div>
      </div>

      <div
        // style={{ gridTemplateColumns: "repeat(4,1fr)" }}
        className="logo-grid top-grid"
      >
        {[
          minimalist,
          earth,
          zone,
          null,
          // null,
          // clubmahindraLogo,
        ].map((logo, i) => (
          <div
            style={{
              // ...([3, 4].includes(i)
              //   ? { borderRight: "none", borderLeft: "none" }
              //   : {}),
              width: "100%",
              // ...(!logo ? { gridColumn: "span 2" } : {}),
            }}
            className="logo_container"
          >
            {!logo ? (
              <h1 style={{ fontSize: "20px" }}>This can be you</h1>
            ) : (
              <img src={logo} alt="Best Western" className="client-logoi" />
            )}
          </div>
        ))}
      </div>
      {modal && <BookingModal onClose={() => setModal(false)} />}

      {/* Center content */}

      {/* Right column logos */}

      {/* Bottom row logos */}
    </section>
  );
};

export default ClientLogosSection;
