import React from "react";
import "./styles/aboutus.css";
import launch from "./svgs/launch.svg";
import hotels from "./svgs/Vector.svg";
import alin from "./svgs/alin.png";
import gtm from "./svgs/gtm.png";
import dd from "./svgs/dd.png";
import tbo from "./svgs/tbo.png";
import map from "./svgs/map.png";
import useWindowSize from "../../utils/useWindowSize";

const milestones = [
  {
    date: "October 2022",
    title: "Hotelzify takes off!",
    description:
      "Founded in Bengaluru with a vision to transform hotel operations with AI-driven solutions.",
    image: launch,
  },
  {
    date: "December 2022",
    title: "Scaling fast!",
    description:
      "Founded in Bengaluru with a vision to transform hotel operations with AI-driven solutions.",
    image: hotels,
  },
  {
    date: "August 2023",
    title: "Backed by industry leaders!",
    description:
      "Secured investments from TBO.com, All In Capital, and leading angel investors.",
    image: launch,
    images: [alin, gtm, dd, tbo],
  },
  {
    date: "Present ✨ ",
    title: "Global impact!",
    description:
      "Empowering 13000+ hotels globally with 5 AI-driven products designed to revolutionize hospitality operations and revenue generation.",
    image: map,
  },
];

const AboutSection = () => {
  const { width } = useWindowSize();

  const isMobile = width <= 768;

  return (
    <div style={{ marginTop: "150px", marginBottom: !isMobile ? "150px" : 0 }}>
      <div className="containerLanding onestfont">
        <h1
          className="text-dark"
          style={{
            fontSize: isMobile ? "36px" : "60px",
            lineHeight: "1.1",
            textAlign: "center",
            fontWeight: "600",
            marginBottom: "3rem",
          }}
        >
          Building the Future of Hospitality {!isMobile && <br />} with{" "}
          <span className="gradient_app onestfont"> AI-Driven Innovation</span>
        </h1>
        {/* <h1
          className="text-dark onestfont"
          style={{
            fontSize: isMobile ? "48px" : "60px",
            textAlign: "center",
            marginBottom: "54px",
          }}
        >
          with{" "}
          <span className="gradient_app onestfont"> AI-Driven Innovation</span>
        </h1> */}
        <p
          className="text-dark aboutus_description_l"
          style={{ textAlign: "center" }}
        >
          Founded in 2020, Hotelzify was created with a clear mission: to
          empower hoteliers to achieve 1 million {!isMobile && <br />} direct
          bookings annually. With cutting-edge AI-powered technology, we provide
          tools to maximize direct {!isMobile && <br />}bookings, streamline
          operations, and elevate guest experiences, helping hotels grow smarter
          and faster.
        </p>
      </div>

      <div style={{ marginTop: "10rem" }} className="about-us-container">
        <h1 style={{ margin: "4rem 0" }} className="journey_title">
          Our Journey
        </h1>
        {milestones.map((milestone, index) => (
          <div
            style={{ ...(index === 3 ? { marginBottom: "94px" } : {}) }}
            className="history-card"
          >
            <div
              style={{
                width: "60%",
                display: "flex",
                flexDirection: "column",
                gap: "12px",
              }}
              className="history-content"
            >
              <h1 className="ms_time">{milestone.date}</h1>
              <h1 className="ms_title">{milestone.title}</h1>
              <h1 className="ms_description">{milestone.description}</h1>
            </div>
            {milestone?.images ? (
              <div className="img_milestone">
                {milestone.images.map((imgs, index) => (
                  <img
                    height={isMobile && index === 2 ? "80" : "auto"}
                    src={imgs}
                    alt=""
                  />
                ))}
              </div>
            ) : (
              <img
                style={{ width: isMobile && index === 3 ? "100%" : "40%" }}
                className="milestone_img"
                src={milestone.image}
                alt=""
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AboutSection;
