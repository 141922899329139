import React, { useState, useEffect } from 'react';
import styles from './CategoriesAndFilters.module.css';
import { getAllBlogCategories } from '../../../Services/blogService';

const CategoriesAndFilters = ({onChange}) => {
  const [activeCategory, setActiveCategory] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
  const [sortBy, setSortBy] = useState('recent');

  const [categories, setCategories] = useState([{title: 'View All', id: null}]);

  const fetchBlogCategories = async () => {
    const response = await getAllBlogCategories();
    if(response.status === 200){
      setCategories((prev) => [...prev, ...response.data || []])
    }
  };

  useEffect(() => {
    fetchBlogCategories()
  },[])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    handleResize();
    
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleCategoryClick = (categoryId) => {
    setActiveCategory(categoryId);
    if (isMobile) {
      setIsCategoryDropdownOpen(false);
    }
    onChange({ categoryId, sortBy })
  };

  const toggleCategoryDropdown = () => {
    setIsCategoryDropdownOpen(!isCategoryDropdownOpen);
  };

  const activeCategoryName = categories.find(cat => cat.id === activeCategory)?.title;

  return (
    <div className={styles.container}>
      {isMobile ? (
        <div className={styles.mobileCategories}>
          <div 
            className={styles.mobileCategorySelector}
            onClick={toggleCategoryDropdown}
          >
            <span>{activeCategoryName}</span>
            <svg 
              className={`${styles.dropdownArrow} ${isCategoryDropdownOpen ? styles.open : ''}`}
              xmlns="http://www.w3.org/2000/svg" 
              width="16" 
              height="16" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            >
              <path d="M6 9l6 6 6-6"/>
            </svg>
          </div>
          
          {isCategoryDropdownOpen && (
            <ul className={styles.mobileCategoriesList}>
              {categories.map(category => (
                <li key={category.id} className={styles.mobileCategoryItem}>
                  <button 
                    className={`${styles.mobileCategoryLink} ${activeCategory === category.id ? styles.activeItem : ''}`}
                    onClick={() => handleCategoryClick(category.id)}
                  >
                    {category?.title}
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      ) : (
        <div className={styles.categoriesWrapper}>
          <ul className={styles.categories}>
            {categories.map(category => (
              <li key={category.id} className={styles.categoryItem}>
                <button 
                  className={`${styles.categoryLink} ${activeCategory === category.id ? styles.active : ''}`}
                  onClick={() => handleCategoryClick(category.id)}
                >
                  {category.title}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      
      <div className={styles.sortContainer}>
        <select className={styles.sortDropdown} value={sortBy} onChange={(e)=>{
          onChange({ categoryId: activeCategory, sortBy: e.value })
          setSortBy(e.value);
        }}>
          <option value="recent">Most recent</option>
          <option value="oldest">Oldest</option>
          <option value="popular">Most popular</option>
        </select>
        <div className={styles.chevronIcon}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M6 9l6 6 6-6"/>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default CategoriesAndFilters;