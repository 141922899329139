import React, { useEffect, useState } from 'react'
import styles from './LatestBlogCard.module.css';
import blogOpenIcon from '../../../assets/svg/blog_open.svg';
import logo from '../../../assets/images/Hotelzify.jpg';
import { getAllBlogs } from '../../../Services/blogService';
import { format } from 'date-fns'
import { useHistory } from 'react-router-dom';

const LatestBlogCard = () => {

    const history = useHistory();

    const [latestBlog, setLatestBlog] = useState({});

    const fetchBlogPosts = async () => {
        const response = await getAllBlogs({});
        console.log({ response })
        setLatestBlog(response?.data?.blogs?.[0])
    };

    useEffect(() => {
        fetchBlogPosts();
    },[])

  return (
    <div className={styles.card_container} style={{ backgroundImage: `url('${latestBlog?.thumbnail}')`}}>
        <div className={styles.cntnt_box}>
            <div>
                <div className={styles.blog_heading} onClick={()=>history.push(`/blogs/${latestBlog?.slug}`)}>
                    <h2>{latestBlog?.title}</h2>
                    <img src={blogOpenIcon} alt="open" />
                </div>
                <p className={styles.blog_excerpt}>{latestBlog?.excerpt}</p>
            </div>
            <div className={styles.blog_details}>
                <div className={styles.blog_author_box}>
                    <div className={styles.blog_dt_card}>
                        <h5>Written by</h5>
                        <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                            <img src={logo} alt="author" />
                            <p>Hotelzify</p>
                        </div>
                    </div>
                    <div className={styles.blog_dt_card}>
                        <h5>Published on</h5>
                        <p>{latestBlog?.createdAt && format(new Date(latestBlog?.createdAt), 'dd MMM yyyy')}</p>
                    </div>
                </div>
                <div className={styles.blog_dt_card}>
                    <h5>File Under</h5>
                    <p className={styles.blog_category}>
                        {latestBlog?.category?.title}
                    </p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default LatestBlogCard