import React, { useEffect, useState } from 'react'
import Navbar from '../../components/landing/navbar/navbar'
import styles from './BlogPage.module.css';
import LatestBlogCard from '../../components/Blog/LatestBlogCard/LatestBlogCard';
import CategoriesAndFilters from '../../components/Blog/CategoriesAndFilters.jsx/CategoriesAndFilters';
import BlogPostCard from '../../components/Blog/BlogCard/BlogCard';
import { getAllBlogs } from '../../Services/blogService';
import Pagination from '../../components/Blog/Pagination/Pagination';
import Footer from '../../components/landing/footer';
import Hero from '../../components/landing/footerCta';

const BlogPage = () => {

    const [blogs, setBlogs] = useState([]);
    const [pagination, setPagination] = useState({});
    const [filters, setFilters] = useState({ categoryId: null, sortBy: "recent", page: 1, limit:10 });

    const fetchBlogPosts = async () => {
        const response = await getAllBlogs(filters);
        console.log({ response })
        setBlogs(response?.data?.blogs || []);
        setPagination(response?.data?.pagination || {});
    };

    useEffect(() => {
        fetchBlogPosts();
    },[filters])

    const handleCategoryAndSortChange = ({ categoryId, sortBy }) => {
        console.log({ categoryId, sortBy });
        setFilters((prev) => {
            return {...prev, categoryId, sortBy};
        })
    };

    const handlePaginationChange = ({ page }) => {
        setFilters((prev) => {
            return {...prev, page};
        })
    }

  return (
    <>
        <div className={styles.blog_header}>
            <Navbar/>   
        </div>
        <div className={styles.blog_container}>
            <div className={styles.blog_heading_box}>
                <h3 className={styles.sub_heading}>Our blog</h3>
                <h1 className={styles.blog_heading}>Resources and insights</h1>
                <p className={styles.blog_tag}>The latest industry news, interviews, technologies, and resources.</p>
            </div>
            <LatestBlogCard/>
            {/* Blog Categories */}
            <CategoriesAndFilters onChange={handleCategoryAndSortChange}/>
            <div className={styles.blog_cards}>
               {
                blogs?.map((blg) => (
                    <BlogPostCard 
                        key={blg?.id}
                        title={blg?.title}
                        excerpt={blg?.excerpt}
                        thumbnail={blg?.thumbnail}
                        createdAt={blg?.createdAt}
                        category={blg?.category?.title}
                        slug={blg.slug}
                    />
                ))
               }
            </div>
            {
                <Pagination
                    totalPages={pagination?.totalPages}
                    currentPage={pagination?.currentPage}
                    onPageChange={handlePaginationChange}
                />
            }
        </div>
        <Hero/>
        <Footer/>
    </>
  )
}

export default BlogPage