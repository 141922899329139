import React, { useState } from "react";
import "./styles/footer.css";
import hotelzify from "../../assets/images/landing/hotelziyf.png";
import useWindowSize from "../../utils/useWindowSize";

const locations = [
  "30N Gould St Ste R, Sheridan, Wyoming, 82801 United States",
  "16358, 63rd PI, Maple Grove, MN 55311, United States",
  "BHIVE Workspace - AKR Tech Park, Bangalore - 560068",
];

const links = [
  {
    section: "Company",
    links: [
      // {
      //   title: "Contact",
      //   url: "mailto:support@hotelzify.com",
      // },
      {
        title: "Terms of Service",
        url: "terms-conditions",
      },
      {
        title: "Privacy Policy",
        url: "privacy-policy",
      },
      {
        title: "FAQs",
        url: "#lfaqs",
      },
    ],
  },
  {
    section: "Platform",
    links: [
      {
        title: "Booking Engine",
        url: "booking-engine",
      },
      {
        title: "Ai Marketing",
        url: "marketing",
      },
      {
        title: "Ai Sales Concierge",
        url: "ai-sales-concierge",
      },
    ],
  },
  // {
  //   section: "Property Types",
  //   links: [
  //     {
  //       title: "Hotel",
  //       url: "/",
  //     },
  //     {
  //       title: "OTA",
  //       url: "/",
  //     },
  //   ],
  // },
  // {
  //   section: "Integrations",
  //   links: [
  //     {
  //       title: "Cloudbeds",
  //       url: "/",
  //     },
  //     {
  //       title: "Beds24",
  //       url: "/",
  //     },
  //     {
  //       title: "Ezee Absolute",
  //       url: "/",
  //     },
  //     {
  //       title: "Apaleo",
  //       url: "/",
  //     },
  //   ],
  // },
];

const Footer = () => {
  const [email, setEmail] = useState("");
  const [subscribe, setSubscribe] = useState("Subscribe");
  const { width } = useWindowSize();

  const isMobile = width <= 768;
  return (
    <footer className="footer-app">
      <div className="footer-container">
        <div className="containerLanding">
          <div className="footer_subscribe">
            <h1 className="subscribe_heading">
              Get Latest Industry News Updates!
            </h1>
            <div>
              {subscribe === "Subscribed" ? (
                <p style={{ color: "white" }}>
                  Thanks for subscribing, we will keep you posted!
                </p>
              ) : (
                <>
                  <input
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    placeholder="Email Adress"
                    type="email"
                    name="email"
                    style={{ width: isMobile ? "auto" : "323px" }}
                  />
                  <button
                    onClick={() => {
                      setSubscribe("Subscribed");

                      // setTimeout(() => {
                      //   setSubscribe("Subscribe");
                      // }, 2000);
                    }}
                    disabled={!email}
                    style={{ cursor: "pointer" }}
                  >
                    {subscribe}
                  </button>
                </>
              )}
            </div>
          </div>
          <div className="links-footer">
            <div>
              <img style={{ marginBottom: "1rem" }} src={hotelzify} alt="" />
              {locations.map((location) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                    marginBottom: "16px",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 12.5C13.6569 12.5 15 11.1569 15 9.5C15 7.84315 13.6569 6.5 12 6.5C10.3431 6.5 9 7.84315 9 9.5C9 11.1569 10.3431 12.5 12 12.5Z"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 22C14 18 20 15.4183 20 10C20 5.58172 16.4183 2 12 2C7.58172 2 4 5.58172 4 10C4 15.4183 10 18 12 22Z"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span
                    style={{
                      color: "white",
                      textWrap: isMobile ? "wrap" : "nowrap",
                    }}
                  >
                    {location}
                  </span>
                </div>
              ))}
            </div>
            <div className="footer-links-new">
              {links.map((link) => (
                <div>
                  <div className="section-title-footer">{link.section}</div>
                  <ul>
                    {link.links.map((link) => (
                      <li className="">
                        <a style={{ color: "white" }} href={link.url}>
                          {link.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
