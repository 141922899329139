import httpService from './httpService';

export const getAllBlogs = (filters) => {
    const {
        page = 1,
        limit = 10,
        sortBy = 'recent',
        categoryId = null,
    } = filters;
    const query = categoryId ? `page=${page}&limit=${limit}&status=published&sortBy=${sortBy}&categoryId=${categoryId}` : `page=${page}&limit=${limit}&status=published&sortBy=${sortBy}`
    return httpService.get(`/hotel/v1/blog?${query}`);
};

export const getAllBlogCategories = () => {
    return httpService.get('/hotel/v1/blog/categories?activeOnly=true');
}

export const getBlogBySlug = (slug) => {
    return httpService.get(`/hotel/v1/blog/slug?slug=${slug}`);
}