import axios from "axios";
import { history } from "../history";
import { fireGtmEvent } from "../utils/googleAnalytics";
import storageService from "./storageService";
import { captureException } from "@sentry/react";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use((request) => {
  const token = storageService.get("token");
  request.headers.Authorization = `Bearer ${token}`;
  return request;
});

axiosInstance.interceptors.response.use(null, (error) => {
  const { status } = error?.response;

  if (status === 401) {
    storageService.remove();
    history.push("/");
  }

  return Promise.reject(error);
});

const tryCatchWrapper = async (axiosObject) => {
  try {
    const { data } = await axiosInstance(axiosObject);
    return data;
  } catch (error) {
    if (error?.response?.status >= 500) {
      captureException(`API: ${axiosObject?.url?.slice(0, 30)} Failed!`, {
        data: { request: axiosObject, error },
      });
    }
    fireGtmEvent("api_error", {
      axiosObject,
      error,
    });

    return error?.response?.data || {};
  }
};

const httpService = {
  get: (url, params) => tryCatchWrapper({ method: "GET", url, params }),
  post: (url, body) => tryCatchWrapper({ method: "POST", url, data: body }),
  put: (url, body) => tryCatchWrapper({ method: "PUT", url, data: body }),
  patch: (url, body) => tryCatchWrapper({ method: "PATCH", url, data: body }),
  delete: (url, body) => tryCatchWrapper({ method: "DELETE", url, data: body }),
};

export default httpService;
