import React, { useState, useEffect } from "react";
import "./styles/count.css";

const Counter = ({ end, duration = 2000, prefix = "", suffix = "" }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let startTime = null;
    const animate = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;
      const percentage = Math.min(progress / duration, 1);

      setCount(Math.floor(end * percentage));

      if (percentage < 1) {
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);
  }, [end, duration]);

  return (
    <span className="counter gradient_app">
      {prefix}
      {count.toLocaleString()}
      {suffix}
    </span>
  );
};

const StatsDashboard = () => {
  return (
    <div className="stats-container">
      <h1 className="stats-title">
        <span className="text-dark onestfont">Fueling Growth & Maximizing Revenue</span>
        <br />
        <span className="text-dark onestfont">
          in <span className="gradient_app onestfont">Hospitality Industry </span>
        </span>
      </h1>

      <div className="stats-grid">
        <div className="stat-card">
          <Counter end={13000} suffix="+" />
          <p className="stat-label">
            Hotels &<br />
            Properties
          </p>
        </div>

        <div className="stat-card">
          <Counter end={100} prefix="$" suffix="K+" />
          <p className="stat-label">
            Average Revenue
            <br />
            Increment
          </p>
        </div>

        <div className="stat-card">
          <Counter end={30} suffix="%" />
          <p className="stat-label">
            Average Occupancy
            <br />
            Increment
          </p>
        </div>
      </div>
    </div>
  );
};

export default StatsDashboard;
