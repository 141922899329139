import React from "react";
import "./landing.css";
import Navbar from "./navbar/navbar";
import Hero from "./Hero";
import StatsDashboard from "./count";
import TestimonialsSection from "./testimonial";
import ClientLogosSection from "./clients";
import ComparisonTable from "./comparison";
import PartnerSection from "./partner";
import FAQ from "./faq";
import FooterHero from "./footerCta";
import Footer from "./footer";
import useWindowSize from "../../utils/useWindowSize";

const LandinPage = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 768;
  return (
    <div className="">
      <Navbar />
      <Hero
        content={
          isMobile ? (
            <>
              <h1 className="onestfont">
                <span className="text-dark">So Many </span> <br />
                <span className="gradient_app onestfont">Direct Bookings,</span>
              </h1>
              <h1 className="text-dark onestfont">
                Your Website <br /> Feels Like an <br />
                OTA
              </h1>
              <p style={{ fontSize: "11px" }}>
                Custom website, AI marketing, and AI sales concierge—everything
                you need for more direct bookings.
              </p>
            </>
          ) : (
            <>
              <h1 className="onestfont">
                <span className="text-dark">So Many </span>
                <span className="gradient_app onestfont">Direct Bookings,</span>
              </h1>
              <h1 className="text-dark onestfont">
                Your Website Feels Like an OTA
              </h1>
              <p>
                Custom website, AI marketing, and AI sales concierge—everything
                you need for more direct bookings.
              </p>
            </>
          )
        }
      />
      <StatsDashboard />
      <TestimonialsSection />
      <ClientLogosSection />
      <ComparisonTable />
      <PartnerSection />
      <FAQ />
      <FooterHero />
      <Footer />
    </div>
  );
};

export default LandinPage;
