import React, { useState } from "react";
import "./bookingModal.css";
import modalSvg from "./svgs/modal.svg";

const BookingModal = ({ onClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    hotelName: "",
    email: "",
    phoneNumber: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    // Add your submission logic here
  };

  const [showCountryDropdown, setShowCountryDropdown] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("+91");

  const countries = ["+1", "+44", "+61", "+91", "+86", "+81", "+49", "+33"];

  const handleCountrySelect = (code) => {
    setSelectedCountry(code);
    setShowCountryDropdown(false);
  };

  return (
    <div className="demo-modal-overlay">
      <div className="demo-modal-container">
        <div onClick={onClose} className="demo-modal-close">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="10" />
            <line x1="15" y1="9" x2="9" y2="15" />
            <line x1="9" y1="9" x2="15" y2="15" />
          </svg>
        </div>

        <div className="demo-modal-content">
          <div className="demo-modal-left">
            <h2 className="demo-modal-title">
              Book a free demo with our experts today!
            </h2>

            <div className="demo-modal-form">
              <div className="demo-form-group">
                <input
                  type="text"
                  className="demo-form-input"
                  placeholder="Your Name*"
                />
              </div>

              <div className="demo-form-group">
                <input
                  type="text"
                  className="demo-form-input"
                  placeholder="Hotel Name*"
                />
              </div>

              <div className="demo-form-group">
                <input
                  type="email"
                  className="demo-form-input"
                  placeholder="Email Address*"
                />
              </div>

              <div className="demo-form-group">
                <div className="demo-phone-input-container">
                  <div
                    className="demo-country-code"
                    onClick={() => setShowCountryDropdown(!showCountryDropdown)}
                  >
                    <span>{selectedCountry}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className={
                        showCountryDropdown
                          ? "demo-chevron-up"
                          : "demo-chevron-down"
                      }
                    >
                      <polyline
                        points={
                          showCountryDropdown
                            ? "18 15 12 9 6 15"
                            : "6 9 12 15 18 9"
                        }
                      />
                    </svg>

                    {showCountryDropdown && (
                      <div className="demo-country-dropdown">
                        {countries.map((code) => (
                          <div
                            key={code}
                            className="demo-country-option"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCountrySelect(code);
                            }}
                          >
                            {code}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <input
                    type="tel"
                    className="demo-phone-input"
                    placeholder="Phone Number*"
                  />
                </div>
              </div>

              <button className="demo-submit-button">Submit</button>
            </div>
          </div>

          <div className="demo-modal-right">
            <div className="demo-benefits">
              <div className="demo-benefit-item">
                <div className="demo-check-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#4CAF50"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <circle cx="12" cy="12" r="10" fill="#E8F5E9" />
                    <polyline points="8 12 11 15 16 9" />
                  </svg>
                </div>
                <span>No commitment, just a friendly walkthrough.</span>
              </div>

              <div className="demo-benefit-item">
                <div className="demo-check-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#4CAF50"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <circle cx="12" cy="12" r="10" fill="#E8F5E9" />
                    <polyline points="8 12 11 15 16 9" />
                  </svg>
                </div>
                <span>Tailored to your business needs.</span>
              </div>

              <div className="demo-benefit-item">
                <div className="demo-check-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#4CAF50"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <circle cx="12" cy="12" r="10" fill="#E8F5E9" />
                    <polyline points="8 12 11 15 16 9" />
                  </svg>
                </div>
                <span>Get your questions answered.</span>
              </div>
            </div>

            <div className="demo-dashboard-preview">
              <img
                src={modalSvg}
                alt="Hotel management dashboard preview"
                className="demo-dashboard-image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingModal;
