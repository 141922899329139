import React from "react";
import "./landing.css";
import Navbar from "./navbar/navbar";
import Hero from "./Hero";
import StatsDashboard from "./count";
import TestimonialsSection from "./testimonial";
import ClientLogosSection from "./clients";
import ComparisonTable from "./comparison";
import PartnerSection from "./partner";
import FAQ from "./faq";
import FooterHero from "./footerCta";
import Footer from "./footer";
import AboutSection from "./AboutSection";
import useWindowSize from "../../utils/useWindowSize";

const AboutUs = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 768;
  return (
    <div className="">
      <Navbar />
      <Hero
        content={
          isMobile ? (
            <>
              <h3 className="product_title_l">About Us</h3>
              <h1 className="onestfont">
                <span className="text-dark onestfont">
                  Elevate your <br />{" "}
                </span>
                <span className="gradient_app onestfont">Hotel’s Success</span>
              </h1>
              <h1 className="text-dark onestfont">with AI-Powered Solutions</h1>
              <p style={{ marginTop: "1rem" }}>
                We help hotels maximize direct bookings, streamline operations,
                and deliver unforgettable guest experiences. With cutting-edge
                AI technology, we’re revolutionizing hospitality and driving
                measurable growth for our partners.
              </p>
            </>
          ) : (
            <>
              <h3 className="product_title_l">About Us</h3>
              <h1 className="onestfont">
                <span className="text-dark onestfont">Elevate your </span>
                <span className="gradient_app onestfont">Hotel’s Success</span>
              </h1>
              <h1 className="text-dark onestfont">with AI-Powered Solutions</h1>
              <p>
                We help hotels maximize direct bookings, streamline operations,
                and deliver unforgettable guest experiences. With cutting-edge
                AI technology, we’re revolutionizing hospitality and driving
                measurable growth for our partners.
              </p>
            </>
          )
        }
      />

      <AboutSection />

      <FooterHero />
      <Footer />
    </div>
  );
};

export default AboutUs;
